
// modules
import { Login } from "commons/modules";

const index = () => {
 return  <Login />;
  
};

export default index;
